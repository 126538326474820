import {Component, ElementRef, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { ProjectsInfoService } from '../projects-info.service';
import * as smoothscroll from 'smoothscroll-polyfill';
import {EventEmitter} from 'events';


@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss']
})
export class ProjectItemComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private projectDataInfos: ProjectsInfoService,
    private router: Router
  ) { }

  @Output() mouseWheelUp = new EventEmitter();
  @Output() mouseWheelDown = new EventEmitter();

  currentProject: any;

  nextProject: any;

  public color: string;

  public bgProject = ['pink', 'blue', 'dark-green'];

  public currentColorIndex = 0;

  @Input() pressed: boolean;

  @ViewChild('widgetsContent', { read: ElementRef, static: false }) private widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    smoothscroll.polyfill();
    this.widgetsContent.nativeElement.scrollTo(
      {left: (this.widgetsContent.nativeElement.scrollLeft + window.innerWidth / 4), behavior: 'smooth'}
    );
  }

  public scrollLeft(): void {
    smoothscroll.polyfill();
    this.widgetsContent.nativeElement.scrollTo(
      {left: (this.widgetsContent.nativeElement.scrollLeft - window.innerWidth / 4), behavior: 'smooth'}
    );
  }

  public scrollToStart(): void {
    smoothscroll.polyfill();
    this.widgetsContent.nativeElement.scrollTo(
      {left: (0), top: (0)}
    );
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.color = params.get('colorId');

      this.currentProject = this.projectDataInfos.getCurrent(Number(params.get('id')));

      this.nextProject = this.projectDataInfos.getNext(Number(params.get('id')));
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  onMousewheel(event) {
    smoothscroll.polyfill();
    if (event.deltaY < 0) {
      this.scrollLeft();
    } else if (event.deltaY > 0) {
      this.scrollRight();
    }
  }

  @HostListener('wheel', ['$event']) onMouseWheelChrome(event: any) {
    this.onMousewheel(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.onMousewheel(event);
  }

  @HostListener('wheel', ['$event']) onMouseWheelIE(event: any) {
    this.onMousewheel(event);
  }

  changeColorBg() {
    if (this.currentColorIndex === this.bgProject.length - 1) {
      this.currentColorIndex = 0;
    } else {
      this.currentColorIndex++;
    }
  }
}
