import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgwWowModule } from 'ngx-wow';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import '../smothScroll';




// Import your library
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FooterComponent } from './footer/footer.component';
import { MainScreenComponent } from './main-screen/main-screen.component';
import { OurClientsComponent } from './our-clients/our-clients.component';
import { SafePipe } from './safe.pipe';
import { WhatDoingComponent } from './what-doing/what-doing.component';
import { ArticleComponent } from './article/article.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactsComponent } from './contacts/contacts.component';
import { OurProjectsComponent } from './our-projects/our-projects.component';
import { ProjectItemComponent } from './project-item/project-item.component';
import { OurProjectMobileComponent } from './our-project-mobile/our-project-mobile.component';
import { MainPageComponent } from './main-page/main-page.component';
import { LoaderComponent } from './loader/loader.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { I18nModule } from './i18n/i18n.module';



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MainScreenComponent,
    OurClientsComponent,
    SafePipe,
    WhatDoingComponent,
    ArticleComponent,
    AboutUsComponent,
    ContactsComponent,
    OurProjectsComponent,
    ProjectItemComponent,
    OurProjectMobileComponent,
    MainPageComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    NgwWowModule,
    ScrollToModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    I18nModule,
  ],
  providers: [{ provide: 'Window',  useValue: window }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
