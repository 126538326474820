import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProjectItemComponent } from './project-item/project-item.component';
import { MainPageComponent } from './main-page/main-page.component';


const routes: Routes = [
  {
    path: '', component: MainPageComponent
  },
  {
    path: 'project-item/:id/:colorId', component: ProjectItemComponent
  },
  {
    path: '**', redirectTo: '/'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
