import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import 'rxjs/add/operator/pairwise';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router,
    private wowService: NgwWowService,
    private translate: TranslateService) {

    this.router.events.subscribe(event => {
      if ( event instanceof NavigationEnd) {
        this.wowService.init();
      }
    });
    translate.setDefaultLang('ua');
  }
}
