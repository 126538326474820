import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewInit {

  constructor() { }

  public hidden;


  @ViewChild('circle', {static: false}) circle: ElementRef;

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.hidden = 'hidden';
    }, 2500);
  }
}
