import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss']
})
export class OurClientsComponent implements OnInit {

  constructor() {}

  activeVideo: string;

  @ViewChild('recommendationSlider', {static: false}) recommendationSlider: SlickCarouselComponent;
  @ViewChild('videoPlayer', {static: false}) videoplayer: ElementRef;
  @ViewChild('videoSliderWrapper', {static: false}) videoSliderWrapper: ElementRef;

  // Video slider
  videoSlides = [
    { src: 'assets/img/review-video/Syngenta-review.mp4' },
    { src: 'assets/img/review-video/VESCO-review.mp4' },
    { src: 'assets/img/review-video/review-Henkel.mp4' }
  ];
  videoSlideConfig = {
    arrows: true,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 680,
        settings: {
          arrows: false
        }
      }
    ]
  };

  // Partner logo slider
  logoSlides = [
    { img: 'assets/img/partners-logo/vesco.svg' },
    { img: 'assets/img/partners-logo/verallia.svg' },
    { img: 'assets/img/partners-logo/syngenta.svg' },
    { img: 'assets/img/partners-logo/skoda.svg' },
    { img: 'assets/img/partners-logo/segodnya.svg' },
    { img: 'assets/img/partners-logo/schwarzkopf.svg' },
    { img: 'assets/img/partners-logo/scholl.svg' },
    { img: 'assets/img/partners-logo/roche.svg' },
    { img: 'assets/img/partners-logo/roger.svg' },
    { img: 'assets/img/partners-logo/perwoll.svg' },
    { img: 'assets/img/partners-logo/persil.svg' },
    { img: 'assets/img/partners-logo/logo.svg' },
    { img: 'assets/img/partners-logo/olx.svg' },
    { img: 'assets/img/partners-logo/oll.svg' },
    { img: 'assets/img/partners-logo/novus.svg' },
    { img: 'assets/img/partners-logo/nike.svg' },
    { img: 'assets/img/partners-logo/nash-sik.svg' },
    { img: 'assets/img/partners-logo/naftogaz.svg' },
    { img: 'assets/img/partners-logo/mont-blanc.svg' },
    { img: 'assets/img/partners-logo/metinbect.svg' },
    { img: 'assets/img/partners-logo/loreal.svg' },
    { img: 'assets/img/partners-logo/kronospan.svg' },
    { img: 'assets/img/partners-logo/klo.svg' },
    { img: 'assets/img/partners-logo/henkel.svg' },
    { img: 'assets/img/partners-logo/ernst.svg' },
    { img: 'assets/img/partners-logo/dupont.svg' },
    { img: 'assets/img/partners-logo/digital.svg' },
    { img: 'assets/img/partners-logo/dow.svg' },
    { img: 'assets/img/partners-logo/corteva.svg' },
    { img: 'assets/img/partners-logo/centrobuv.svg' },
    { img: 'assets/img/partners-logo/bosh.svg' },
    { img: 'assets/img/partners-logo/basf.svg' },
    { img: 'assets/img/partners-logo/azot.svg' },
    { img: 'assets/img/partners-logo/azpo.svg' },
    { img: 'assets/img/partners-logo/arzinger.svg' },
    { img: 'assets/img/partners-logo/aeromall.svg' },
    { img: 'assets/img/partners-logo/adidas.svg' },
  ];
  logoSlideConfig = {
    slidesToShow: 15,
    slidesToScroll: 1,
    autoplay: true,
    infinite: true,
    speed: 4000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: false,
    pauseOnFocus: false,
    dots: false,
    arrows: false,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 12
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 10
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 7
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 5
        }
      }
    ]
  };

  // Recommendation slider
  recommendationSlides = [
    { img: 'assets/img/recommendation-images/DOW.jpg' },
    { img: 'assets/img/recommendation-images/EBA.jpg' },
    { img: 'assets/img/recommendation-images/Henkel.jpeg' },
    { img: 'assets/img/recommendation-images/OLL.tv_Digital-Screens.jpg' },
    { img: 'assets/img/recommendation-images/OLX.jpg' },
    { img: 'assets/img/recommendation-images/OSTCHEM.jpg' },
    { img: 'assets/img/recommendation-images/SKP.jpg' },
    { img: 'assets/img/recommendation-images/Syngenta.jpg' },
  ];
  recommendationSlideConfig = {
    arrows: true,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    infinite: true,
    cssEase: 'linear'
  };

  isActive: boolean;

  initSlider() {
    setTimeout(() => {
      this.recommendationSlider.unslick();
      this.recommendationSlider.initSlick();
    }, 500);
  }

  ngOnInit() {
  }

  playVideo(thisElement: HTMLVideoElement) {
    thisElement.play();
    this.activeVideo = 'activeVideo';
  }

  changeVideoSlide(thisElement: HTMLElement) {
    this.activeVideo = '';
    const videos = thisElement.querySelectorAll('video');
    videos.forEach((v: HTMLVideoElement) => v.pause());
  }
}
