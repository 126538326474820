import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectsInfoService {

  private projectDataInfos: any[] = [
    {
      id: 1,
      lang: 'ua',
      name: `Business «FORUM SYNGENTA»`,
      src: `assets/img/projects/forum-syngenta/main.png`,
      mainImageSmall: `assets/img/projects/forum-syngenta/1.png`,
      mainDescription: '<p>BF Forum - щорічна конференція для дистриб\'юторів, яка завжди проводиться на найвищому рівні. Наша агенція розробила креативну концепцію, в якій було проведено тонку паралель між світовим лідерством українських аграріїв та спортсменів. І ті й інші досягають світового визнання результатів не завдяки, а всупереч! Тому що люди цих двох сфер ідуть до своєї мети, незважаючи ні на що, і докладають титанічних зусиль задля досягнення найкращих результатів.</p> <p>Захід проходив у два етапи: ділова частина і розважальна програма. Конференція відбулася в олімпійському дворику НСК "Олімпійський", а нагородження - у президентській ложі. Головними сюрпризами форуму стали поява на заході багаторазової чемпіонки світу з художньої гімнастики Ганни Безсонової та неймовірне лазерно-світлове шоу в чаші стадіону. Під час цього шоу кожен гість міг побачити логотип своєї компанії, створений поєднанням лазерного променя та диму.</p>',
      placeInfo: `<p><span>Замовник:</span>Syngenta</p><p><span>Мета:</span>підвищення рівня лояльності ключових клієнтів</p><p><span>Місце:</span>НСК  "Олімпійський", м. Київ</p><p><span>Гості:</span>80 осіб</p>`,
      slidesWrapper: `<div class="slide video-wrapper"><video muted="muted" controls="controls" playsinline="playsinline" autoplay="autoplay" loop="loop"><source src="assets/img/projects/forum-syngenta/BF_Forum_Out_video.mp4" type="video/mp4"> </video></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/forum-syngenta/3.png" alt="" /> </div><div class="slide full-width small"> <img src="assets/img/projects/forum-syngenta/4.png" alt="" /> </div><div class="slide photo padding extra-small"> <img src="assets/img/projects/forum-syngenta/5.png" alt="" /> </div><div class="slide full-width"> <img src="assets/img/projects/forum-syngenta/6.png" alt="" /> </div><div class="slide photo padding extra-small"> <img src="assets/img/projects/forum-syngenta/7.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/forum-syngenta/8.png" alt="" /> </div><div class="slide photo padding extra-small"> <img src="assets/img/projects/forum-syngenta/9.png" alt="" /></div>`,
    },
    {
      id: 2,
      lang: 'ua',
      name: `Національна конференція «HENKEL»`,
      src: `assets/img/projects/henkel/main.png`,
      mainImageSmall: `assets/img/projects/henkel/1.png`,
      mainDescription: '<p>Для того щоб надати конференції новизни і емоційно залучити учасників, агентство запропонувало компанії новий підхід, зробивши всіх учасників командою героїв, що рятують галактику. За основу взяли серію фільмів "Люди в чорному ". Це дозволило додати конференції трохи фану. </p> <p>У тренувальному таборі гостей зустрічав мопс, що розмовляє - агент Френк. Всі учасники пройшли процедуру «нейралізаціі» і «стирання відбитків пальців». За три дні агенти навчилися швидко і дружньо змиватися в басейн, потренували мозок у Мозгобойні, освоїли інопланетну зброю, знешкодили межгалактичну тварюку, побували на неонової вечірці  на іншій планеті, врятували там своговченого та  розіслали по всій галактиці рятівну формулу.\',</p>',
      placeInfo: `<p><span>Клієнт:</span>Henkel Україна.</p><p><span>Формат:</span>щорічна виїзна конференція компанії Henkel Україна</p><p><span>Цілі:</span>організувати якісне забезпечення конференц-частини і надихнути на досягнення нових планів і завдань на наступний бізнес-рік</p><p><span>Охоплення:</span>100 чоловік.</p>`,
      slidesWrapper: `<div class="slide video-wrapper"><video muted="muted" controls="controls" playsinline="playsinline" autoplay="autoplay" loop="loop"><source src="assets/img/projects/henkel/Henkel_Out.mp4" type="video/mp4"></video></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/3.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/henkel/4.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/5.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/henkel/6.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/7.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/henkel/8.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/9.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/henkel/10.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/11.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/henkel/12.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/13.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/henkel/14.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/15.png" alt="" /></div>`,
    },
    {
      id: 3,
      lang: 'ua',
      name: `Корпоративний «НОВИЙ РІК»`,
      src: `assets/img/projects/new-year/main.png`,
      mainImageSmall: `assets/img/projects/new-year/1.png`,
      mainDescription: `<p>Найбільший в Україні панорамний екран 360 площею 548 кв. м.  Цей проект став номінантом на престижну міжнародну премію Visual Artist Awards в Лос-Анджелесі.</p> <p>На святі компанія провела благодійний збір коштів і закупила комп'ютерне обладнання для дітей зі слабким зором.</p>`,
      placeInfo: `<p><span>Корпоративний новий рік "Ніч у театрі" в цифрах:</span></p><p>800 гостей</p><p>2500 кв. м  площа забудови</p><p>7,8 тонн металоконструкцій</p><p>4 км ферм</p> <p>2,5 км кабелю</p> <p>64 кв.м сценічних конструкцій</p> <p>34 артиста</p> <p>37 м фотозони</p> <p>2019 кг їжі</p> <p>250 осіб персоналу</p>`,
      slidesWrapper: `<div class="slide video-wrapper"><video muted="muted" controls="controls" playsinline="playsinline" autoplay="autoplay" loop="loop"><source src="assets/img/projects/new-year/Out.mp4" type="video/mp4"></video></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/3.png" alt="" /></div><div class="slide full-width"><img src="assets/img/projects/new-year/4.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/5.png" alt="" /></div><div class="slide full-width"><img src="assets/img/projects/new-year/6.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/7.png" alt="" /></div><div class="slide full-width"><img src="assets/img/projects/new-year/8.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/9.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/new-year/10.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/11.png" alt="" /></div><div class="slide photo photo full-width"><img src="assets/img/projects/new-year/12.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/13.png" alt="" /></div><div class="slide photo photo full-width"><img src="assets/img/projects/new-year/14.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/15.png" alt="" /></div>`,
    },
    {
      id: 4,
      lang: 'ua',
      name: `День поля «SYNGENTA»`,
      src: `assets/img/projects/field-day-sengenta/main.png`,
      mainImageSmall: `assets/img/projects/field-day-sengenta/1.png`,
      mainDescription: `<p>Серед безкраїх полів Черкаської області ми побудували містечко з білосніжних наметів, зон активностей, 24-метрової лінії кейтерингу, лаундж-зони і декорували все в стилістиці заходу. Складність проекту полягала в тому, що він тривав три дні поспіль, а тому наша команда доклала чимало зусиль, щоб усі процеси працювали як єдиний справний механізм, незважаючи на примхи погоди.</p> <p>Проект у цифрах: 20 тонн декорацій, 5 тонн відео- та звукової техніки,  6,5 км кабелю, 4500 дерев'яних міток, 8 км огороджувальної стрічки, 54 одиниці персоналу обслуговування + 1 робот, 14 наметів, 2 лінії кейтерингу, 4 зони активностей, незліченні масиви інформації, і як результат - 950 щасливих гостей.</p>`,
      placeInfo: `<p><span>Замовник:</span>Syngenta</p><p><span>Задачі:</span>продемонструвати ефективність препаратів і правильне їх використання. Як наслідок - збільшення продажів.</p><p><span>Місце:</span>с. Саморідня Черкаської області</p><p><span>Гості:</span>950 осіб за 3 дні</p>`,
      slidesWrapper: `<div class="slide photo full-width"><img src="assets/img/projects/field-day-sengenta/2.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/3.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/field-day-sengenta/4.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/5.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/field-day-sengenta/6.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/7.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/field-day-sengenta/8.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/9.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/field-day-sengenta/10.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/11.png" alt="" /></div>`
    },
    {
      id: 5,
      lang: 'ua',
      name: `Тімбілдінг «OLX»`,
      src: `assets/img/projects/olx-team-building/main.png`,
      mainImageSmall: `assets/img/projects/olx-team-building/1.png`,
      mainDescription: `<p>Перша частина fun quest: кожне завдання відображало одну з цінностей компанії. У фіналі барабанний тімбілдінг. В цілому, програма була побудована таким чином, щоб в розважальному форматі продемонструвати суть командної роботи, в якій кожен учасник поділяє цінності компанії, керується єдиною метою і впливає на загальний результат.</p> <p>Друга частина - підсумкова міні-конференція, обговорення мети і планів компанії.</p> <p>Вечірня частина - шоу світлових барабанщиків і дискотека.</p>`,
      placeInfo: `<p><span>Компанія:</span>OLX</p><p><span>Місце:</span>заміський комплекс під Києвом</p><p><span>Формат:</span>командноутворюючий  захід - open-air</p><p><span>Завдання:</span>презентувати і на практиці прожити оновлені корпоративні цінності</p><p><span>Гостей:</span>60 осіб</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/olx-team-building/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/olx-team-building/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/olx-team-building/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/7.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/olx-team-building/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/9.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/olx-team-building/10.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/11.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/olx-team-building/12.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/13.png" alt="" /></div>`,
    },
    {
      id: 6,
      lang: 'ua',
      name: `AGH Forum`,
      src: `assets/img/projects/agh-forum/main.png`,
      mainImageSmall: `assets/img/projects/agh-forum/1.png`,
      mainDescription: `<p>AGH Forum - щорічний захід для керівників агрохолдингів, тому вимоги до організаторів дуже високі. Нами було розроблено візуальну концепцію заходу і виготовлено сценічні конструкції, стійку реєстрації, підготовлено роздаткові матеріали, здійснено брендинг локації і створено нагороди. </p> <p>На ділову частину були запрошені провідні економічні експерти. На урочистій вечірній частині виступила талановита співачка Христина Соловій. Також було організовано виступ та майстер-клас із малювання на воді Ебру. Ведучим заходу був один із найкращих українських шоуменів Юрій Горбунов.</p>`,
      placeInfo: `<p><span>Замовник:</span>Syngenta</p><p><span>Мета:</span>лояльність клієнтів через доповіді відомих експертів у економічній сфері агробізнесу</p><p><span>Місце:</span>м. Львів, готель Citadel Inn </p><p><span>Гості:</span>40 осіб</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/agh-forum/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/agh-forum/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/agh-forum/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/agh-forum/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/agh-forum/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/agh-forum/7.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/agh-forum/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/agh-forum/9.png" alt="" /></div>`,
    },
    {
      id: 7,
      lang: 'ua',
      name: `Новорічне свято «КРАЇНА ЧУДЕС OLX»`,
      src: `assets/img/projects/ny-olx/main.png`,
      mainImageSmall: `assets/img/projects/ny-olx/1.png`,
      mainDescription: `<p>Пройшовши чарівний тунель через кролячу нору, гості потрапляли до країни чудес ОLX, де "все купується і все продається". Там їх зустрічали ходулісти в тематичних костюмах, а також Пікова дама і Валет, осипаючи чарівним блискучим порошком, пригощали грибочками та запрошували до Задзеркалля ОLX. Було організовано багато незалежних зон активностей: крикет із їжаком і ключками з рожевих фламінго, фотобудка з тематичної бутафорією, майстер-класи з чаклування та багато тематичних інтерактивних заходів.</p> <p>Фоном для сцени стали декорації казкового лісу. Зал оформлено психоделічними декораціями, денспол - як шахівниця. Все у фірмових кольорах компанії. На екранах демонструвалися сюжети казки, звучали улюблені вислови...</p> <p>Фільм про Алісу багатий на образи, цитати, гумор - це надало можливість співробітникам компанії стати учасниками продумування власних образів, костюмів, макіяжу і створило неповторну атмосферу казкового свята.</p>`,
      placeInfo: `<p><span>Замовник:</span>компанія OLX</p><p><span>Формат:</span>корпоративне свято</p><p><span>Мета:</span>згуртувати співробітників компанії з різних країн, відсвяткувати Новий рік</p><p><span>Місце:</span>TAO Dance Club, м. Київ</p><p><span>Гості:</span>150 осіб</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/ny-olx/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/ny-olx/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/ny-olx/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/7.png" alt="" /></div><div class="slide photo full-width"> <img src="assets/img/projects/ny-olx/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/9.png" alt="" /></div>`,
    },
    {
      id: 8,
      lang: 'ua',
      name: `Корпоративний Новий рік «HENKEL»`,
      src: `assets/img/projects/ny-henkel/main.png`,
      mainImageSmall: `assets/img/projects/ny-henkel/1.png`,
      mainDescription: `<p>Під керівництвом веселих і талановитих дівчат та хлопців з балету "Ва-Банк" співробітники створили приголомшливе шоу, яке демонструвало найважливіші моменти розвитку компанії Henkel протягом 138 років своєї історії.</p> <p>Співробітники довели, що гідні стати частиною історії успіху компанії-лідера! А ми в черговий раз переконалися, що немає свята краще, ніж те, що зроблене своїми руками!</p> <p>Важливо, що це свято було створено завдяки участі самих співробітників компанії "Henkel Україна", які героїчно готувалися вечорами в самий складний і самий завантажений місяць року.</p>`,
      placeInfo: `<p><span>Компанія:</span>Henkel Україна</p><p><span>Формат:</span>корпоративне свято</p><p><span>Мета:</span>посилення внутрішньокорпоративної лояльності через усвідомлення дотичності до успішної компанії з більш ніж 130-річним досвідом</p><p><span>Гостей:</span>120 осіб</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/ny-henkel/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-henkel/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/ny-henkel/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-henkel/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/ny-henkel/6.png" alt="" /></div><div class="slide padding extra-small"> <img src="assets/img/projects/ny-henkel/7.png" alt="" /></div>`,
    },
    {
      id: 9,
      lang: 'ua',
      name: `Літня майстерня садівництва «SYNGENTA»`,
      src: `assets/img/projects/summer-syngenta/main.png`,
      mainImageSmall: `assets/img/projects/summer-syngenta/1.png`,
      mainDescription: `<p>Ви знаєте, хто така Оленка Волохата? А чим відрізняється великодисперсна крапля від дрібнодисперсної? А якими препаратами краще захищати яблука від шкідників? А ми тепер знаємо! 3 роки поспіль наша команда проводила "Майстерні садівництва" у найкращих садах Вінницької, Херсонської та Чернівецької областей.</p> <p>Організовуючи декор заходу, дозвілля гостей і смачну святкову вечерю, ми в свою чергу удосконалили власні організаційні навички та дізналися багато нового із яблуневого світу.</p>`,
      placeInfo: `<p><span>Замовник:</span>Syngenta</p><p><span>Мета:</span>донести інформацію про нові продукти компанії і навчити їх правильному використанню для збільшення обсягів урожаю господарствами</p><p><span>Місце:</span>садові господарства Вінницької та Чернівецької областей</p><p><span>Гості:</span>400 осіб</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/3.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/7.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/9.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/summer-syngenta/10.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/11.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/12.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/13.png" alt="" /></div>`,
    },
    {
      id: 10,
      lang: 'ua',
      name: `HENKEL Summer Conference`,
      src: `assets/img/projects/summer-henkel/main.jpg`,
      mainImageSmall: `assets/img/projects/summer-henkel/1.png`,
      mainDescription: `<p>Для реалізації цього проекту агенція розробила концепцію заходу та айдентику (лого, сувенірна продукція, подарунки, нагороди, рекламні конструкції для брендингу залу й інших локацій); дібрала варіанти розміщення і проведення всіх складників проекту; організувала поселення, трансфери; забезпечила харчування протягом усіх трьох днів проекту; забезпечила підтримку і сприяння в реалізації нестандартних презентацій співробітників з елементами шоу; розробила і реалізувала розважальні та командоутворювальні активності (гала-вечеря, відеопрезентація команд, яхтова регата, квести).</p>`,
      placeInfo: `<p><span>Компанія:</span>Henkel Україна</p><p><span>Формат:</span>щорічна виїзна конференція компанії Henkel Україна </p><p><span>Мета:</span>організувати якісне забезпечення конференц-частини і надихнути на досягнення нових планів і завдань на наступний бізнес-рік</p><p><span>Місце:</span>м. Одеса</p><p><span>Гості:</span>150 осіб</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/summer-henkel/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/summer-henkel/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-henkel/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/7.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/summer-henkel/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/9.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/summer-henkel/10.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/11.png" alt="" /></div>`,
    },



    {
      id: 11,
      lang: 'en',
      name: `Business FORUM SYNGENTA `,
      src: `assets/img/projects/forum-syngenta/main.png`,
      mainImageSmall: `assets/img/projects/forum-syngenta/1.png`,
      mainDescription: '<p>Business FORUM is an annual conference for distributors, which is always held at the highest level. Our Agency has developed a creative concept in which a subtle parallel was drawn between the world  leadership of Ukrainian farmers and athletes. Both of them got the  international recognition of their results, not due to something, but despite everything! As people working in these two areas  reach their goals no matter what, and apply Herculean efforts to achieve the best results.</p> <p>The event was divided into two stages: business and entertainment. The conference was held in the Olympic courtyard of NSC "Olympic", and the award ceremony was in the Presidential box. The main surprise of the forum was the performance of multiple world champion in rhythmic gymnastics Anna Bessonova and an incredible laser show on the stadium. During this show, each guest could see its company logo created by a combination of laser beam and smoke.</p>',
      placeInfo: `<p><span>Customer:</span>Syngenta</p><p><span>Goal:</span>increase the level of loyalty of the key customers</p><p><span>Location:</span>NSC "Olympic", Kiev</p><p><span>Guests:</span>80 people</p>`,
      slidesWrapper: `<div class="slide video-wrapper"><video controls="controls" muted="muted" playsinline="playsinline" autoplay="autoplay" loop="loop"><source src="assets/img/projects/forum-syngenta/BF_Forum_Out_video.mp4" type="video/mp4"> </video></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/forum-syngenta/3.png" alt="" /> </div><div class="slide full-width small"> <img src="assets/img/projects/forum-syngenta/4.png" alt="" /> </div><div class="slide photo padding extra-small"> <img src="assets/img/projects/forum-syngenta/5.png" alt="" /> </div><div class="slide full-width"> <img src="assets/img/projects/forum-syngenta/6.png" alt="" /> </div><div class="slide photo padding extra-small"> <img src="assets/img/projects/forum-syngenta/7.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/forum-syngenta/8.png" alt="" /> </div><div class="slide photo padding extra-small"> <img src="assets/img/projects/forum-syngenta/9.png" alt="" /></div>`,
    },
    {
      id: 12,
      lang: 'en',
      name: `National Conference HENKEL `,
      src: `assets/img/projects/henkel/main.png`,
      mainImageSmall: `assets/img/projects/henkel/1.png`,
      mainDescription: '<p>In order to provide the conference with novelty and engage participants emotionally, the Agency offered the company a new approach, making all participants a team of heroes saving the galaxy. It was based on a series of "Men in black". This added some fun to the conference.</p> <p>At the training camp, guests were greeted by talking pug agent Frank. All participants underwent the procedure of "neutralization" and "erasing fingerprints". In three days, the agents learned to quickly and amicably wash away in the pool, trained the brain in the Brainwash, mastered alien weapons, neutralized the intergalactic creature, visited a neon party on another planet, saved their scientist and sent out a saving formula throughout the galaxy.,</p>',
      placeInfo: `<p><span>Customer:</span>Henkel Україна.</p><p><span>Type:</span>annual visiting conference of Henkel Ukraine company</p><p><span>Goals:</span>to organize high-quality conference and inspire to achieve new plans and objectives for the next business year</p><p><span>Guests:</span>100 people.</p>`,
      slidesWrapper: `<div class="slide video-wrapper"><video controls="controls" muted="muted" playsinline="playsinline" autoplay="autoplay" loop="loop"><source src="assets/img/projects/henkel/Henkel_Out.mp4" type="video/mp4"></video></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/3.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/henkel/4.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/5.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/henkel/6.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/7.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/henkel/8.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/9.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/henkel/10.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/11.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/henkel/12.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/13.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/15.png" alt="" /></div>`,
    },
    {
      id: 13,
      lang: 'en',
      name: `Corporative NEW YEAR`,
      src: `assets/img/projects/new-year/main.png`,
      mainImageSmall: `assets/img/projects/new-year/1.png`,
      mainDescription: `<p>The largest 360 panoramic screen in Ukraine with an area of 548 square meters, this project was a nominee for the prestigious international Visual Artist Awards in Los Angeles.</p> <pAt the festival, the company held a charity fundraising event and purchased computer equipment for children with poor eyesight.</p>`,
      placeInfo: `<p><span>"Night at the theater" in numbers:</span></p><p>800 guests</p><p>2500 sq m of the building area</p><p>7.8 tons of metal structures</p><p>4 km of farms</p> <p>2.5 km of cable</p> <p>64 sq m of stage constructions</p> <p>34 artists</p> <p>37 m photo zone</p> <p>2019 kg of food</p> <p>250 staff members</p>`,
      slidesWrapper: `<div class="slide video-wrapper"><video controls="controls" muted="muted" playsinline="playsinline" autoplay="autoplay" loop="loop"><source src="assets/img/projects/new-year/Out.mp4" type="video/mp4"></video></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/3.png" alt="" /></div><div class="slide full-width"><img src="assets/img/projects/new-year/4.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/5.png" alt="" /></div><div class="slide full-width"><img src="assets/img/projects/new-year/6.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/7.png" alt="" /></div><div class="slide full-width"><img src="assets/img/projects/new-year/8.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/new-year/9.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/new-year/10.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/11.png" alt="" /></div><div class="slide photo photo full-width"><img src="assets/img/projects/henkel/12.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/13.png" alt="" /></div><div class="slide photo photo full-width"><img src="assets/img/projects/henkel/14.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/henkel/15.png" alt="" /></div>`,
    },
    {
      id: 14,
      lang: 'en',
      name: `Field Days SYNGENTA`,
      src: `assets/img/projects/field-day-sengenta/main.png`,
      mainImageSmall: `assets/img/projects/field-day-sengenta/1.png`,
      mainDescription: `<p>Among the endless fields of Cherkasy region, we built a town of snow-white tents, activity areas, 24-meter catering line, lounge area and decorated everything in the style of the event. The complexity of the project was that it lasted three days in a row, and therefore our team made a lot of effort to ensure that all processes worked as a single mechanism, despite the whims of the weather.</p> <p>The project in numbers: 20 tons of decorations, 5 tons of video and sound equipment, 6.5 km of cable, 4500 wooden tags, 8 km of protective tape, 54 stuff members + 1 robot, 14 tents, 2 catering lines, 4 activity areas, countless arrays of information, and as a result-950 happy guests.</p>`,
      placeInfo: `<p><span>Customer:</span>Syngenta</p><p><span>Goals:</span>to demonstrate the effectiveness of medicine and their proper use. As a result -  the  increase of sales.</p><p><span>Location:</span>Samorodnya village,  Cherkasy region</p><p><span>Guests:</span>950 people in 3 days</p>`,
      slidesWrapper: `<div class="slide photo full-width"><img src="assets/img/projects/field-day-sengenta/2.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/3.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/field-day-sengenta/4.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/5.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/field-day-sengenta/6.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/7.png" alt="" /></div><div class="slide photo full-width"><img src="assets/img/projects/field-day-sengenta/8.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/9.png" alt="" /></div><div class="slide photo full-width small"><img src="assets/img/projects/field-day-sengenta/10.png" alt="" /></div><div class="slide photo padding extra-small"><img src="assets/img/projects/field-day-sengenta/11.png" alt="" /></div>`
    },
    {
      id: 15,
      lang: 'en',
      name: `Team-building OLX`,
      src: `assets/img/projects/olx-team-building/main.png`,
      mainImageSmall: `assets/img/projects/olx-team-building/1.png`,
      mainDescription: `<p>The first part of fun quest: each task reflected one of the company's values. Finally there was team building in the end. In the whole, the program was built in such a way as to demonstrate the essence of teamwork in an entertaining format, in which each participant shares the values of the company, is guided by a single goal and affects the overall result.</p> <p>The second part is the final mini-conference, discussion of the company's goals and plans.</p> <p>The evening part is a show of light drummers and a disco.</p>`,
      placeInfo: `<p><span>Company:</span>OLX</p><p><span>Location:</span>country complex near Kiev</p><p><span>Type:</span>Team-building event - open-air</p><p><span>Task:</span>to present and encounter the updated corporate values in practice</p><p><span>Guests:</span>60 people</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/olx-team-building/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/olx-team-building/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/olx-team-building/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/7.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/olx-team-building/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/9.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/olx-team-building/10.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/11.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/olx-team-building/12.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/olx-team-building/13.png" alt="" /></div>`,
    },
    {
      id: 16,
      lang: 'en',
      name: `AGH Forum `,
      src: `assets/img/projects/agh-forum/main.png`,
      mainImageSmall: `assets/img/projects/agh-forum/1.png`,
      mainDescription: `<p>AGH Forum is an annual event for managers of agricultural holdings, so the requirements for the organizers are very high. We have developed a visual concept of the event and elaborated the design of the stage, reception, prepared handouts, implemented the branding of the location and created awards.</p> <p>Leading economic experts were invited to the business part. A talented singer Christina Solovey made a performance at the solemn part of the evening. We also organized a performance and  Ebru master class in drawing on the water. Yuri Gorbunov, one of the leading Ukrainian showman, was the anchorperson.</p>`,
      placeInfo: `<p><span>Customer:</span>Syngenta</p><p><span>Goal:</span>customer loyalty by means of reports of well-known experts in the economic area of agricultural business</p><p><span>Location:</span>Lviv, Citadel Inn Hotel</p><p><span>Guests:</span>40 people</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/agh-forum/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/agh-forum/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/agh-forum/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/agh-forum/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/agh-forum/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/agh-forum/7.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/agh-forum/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/agh-forum/9.png" alt="" /></div>`,
    },
    {
      id: 17,
      lang: 'en',
      name: `New Year Celebration  OLX`,
      src: `assets/img/projects/ny-olx/main.png`,
      mainImageSmall: `assets/img/projects/ny-olx/1.png`,
      mainDescription: `<p>Having passed through a magical tunnel in a rabbit hole, guests were taken to the Wonderland of OLX, where "everything is bought and everything is sold". There they were met by stilts in themed costumes, as well as the Queen of spades and the Knave, showering everyone with  magic shiny powder, treated with mushrooms and invited to the looking glass OLX. Many independent activity areas were organized: cricket with hedgehog and pink Flamingo clubs, photo booth with themed props, magic workshops and many thematic interactive events.</p> <p>The scenery of the fairy forest became the background for the scene. The hall is decorated with psychedelic decorations, dance floor was like a chess Board. Everything was in the company's corporate colors. The screens showed the stories of the fairy tale, favorite sayings sounded...</p> <p>The film about Alice is rich in images, quotes, humor - it provided an opportunity for the company's employees to become participants in the development of their own images, costumes, makeup and created a unique atmosphere of a fabulous holiday.</p>`,
      placeInfo: `<p><span>Customer:</span>OLX company</p><p><span>Type:</span>Corporate event</p><p><span>Goal:</span>to unite employees of the company from different countries to celebrate New year</p><p><span>Location:</span>TAO Dance Club, Kiev</p><p><span>Guests:</span>150 people</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/ny-olx/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/ny-olx/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/ny-olx/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/7.png" alt="" /></div><div class="slide photo full-width"> <img src="assets/img/projects/ny-olx/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/9.png" alt="" /></div>`,
    },
    {
      id: 18,
      lang: 'en',
      name: `New Year Corporate Event HENKEL`,
      src: `assets/img/projects/ny-henkel/main.png`,
      mainImageSmall: `assets/img/projects/ny-henkel/1.png`,
      mainDescription: `<p>Under the guidance of funny and talented girls and guys from the ballet "VA-Bank" employees created a stunning show that demonstrated the most important moments of the development of the company Henkel during 138 years of its history. </p> <p>Employees have proved that they deserve to be a part of the success story of the leader company! And we are once again convinced that there is no celebration better than the one made by yourself!</p> <p>It is important that this holiday was created thanks to the participation of the employees of the company "Henkel Ukraine", who heroically prepared in the evenings during the most difficult and busiest month of the year.</p>`,
      placeInfo: `<p><span>Company:</span>Henkel Україна</p><p><span>Type:</span>Corporate Event</p><p><span>Goal:</span>strengthening internal corporate loyalty through awareness of belonging to a successful company with more than 130 years of experience</p><p><span>Guests:</span>120 people</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/ny-olx/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/ny-olx/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/ny-olx/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/7.png" alt="" /></div><div class="slide photo full-width"> <img src="assets/img/projects/ny-olx/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/ny-olx/9.png" alt="" /></div>`,
    },
    {
      id: 19,
      lang: 'en',
      name: `Summer Gardening Workshops SYNGENTA`,
      src: `assets/img/projects/summer-syngenta/main.png`,
      mainImageSmall: `assets/img/projects/summer-syngenta/1.png`,
      mainDescription: `<p>Do you know who Alenka Volokhata is? And what is the difference between a coarse drop and a finely dispersed drop? And what drugs are better to protect apples from pests? We know now! 3 years in a row, our team held a "Gardening Workshop" in the best gardens of Vinnytsia, Kherson and Chernivtsi regions.</p>`,
      placeInfo: `<p><span>Customer:</span>Syngenta</p><p><span>Goal:</span>to inform about new products of the company and to teach their correct use for increasing the amount of  crops on the farms.</p><p><span>Location:</span>garden farms in Vinnitsa and Chernivtsi regions</p><p><span>Guests:</span>400 people</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/3.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/7.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/9.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/summer-syngenta/10.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/11.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-syngenta/12.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-syngenta/13.png" alt="" /></div>`,
    },
    {
      id: 20,
      lang: 'en',
      name: `HENKEL Summer Conference `,
      src: `assets/img/projects/summer-henkel/main.jpg`,
      mainImageSmall: `assets/img/projects/summer-henkel/1.png`,
      mainDescription: `<p>To implement this project, the Agency developed the concept of the event and its identity (logo, souvenirs, gifts, awards, advertising designs for branding the hall and other locations); found accommodation options and event conducting  components of the project; organized the settlement, transfers; provided food for all three days of the project; provided support and assistance in the implementation of non-standard presentations of employees with elements of the show; developed and implemented entertainment and team activities (gala dinner, video presentation of teams, yacht regatta, quests).</p>`,
      placeInfo: `<p><span>Customer:</span>Henkel Україна</p><p><span>Type:</span>annual visiting conference of Henkel Ukraine company </p><p><span>Goal:</span>organize high-quality provision of the conference part and inspire to achieve new plans and objectives for the next business year</p><p><span>Location:</span>Odessa</p><p><span>Guests:</span>150 people</p>`,
      slidesWrapper: `<div class="slide full-width"> <img src="assets/img/projects/summer-henkel/2.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/3.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/summer-henkel/4.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/5.png" alt="" /></div><div class="slide full-width"> <img src="assets/img/projects/summer-henkel/6.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/7.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/summer-henkel/8.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/9.png" alt="" /></div><div class="slide full-width small"> <img src="assets/img/projects/summer-henkel/10.png" alt="" /></div><div class="slide photo padding extra-small"> <img src="assets/img/projects/summer-henkel/11.png" alt="" /></div>`,
    }
  ];

  getData(): any [] {
    return this.projectDataInfos.filter(item => item.lang === this.translate.currentLang);
  }

  getCurrent(id: number): any {
    return this.projectDataInfos.find(item => item.id === id);
  }

  getNext(currentId: any) {
    const index = this.projectDataInfos.findIndex(item => item.id === currentId);

    if (index > -1 && this.projectDataInfos.length > index && currentId !== 10) {
      return this.projectDataInfos[index + 1];
    } else {
      return false;
    }
  }

  constructor(public translate: TranslateService) {
  }
}

