import { Component, HostListener, OnInit, AfterViewInit,  Inject, Input, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import 'rxjs/add/observable/fromEvent';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss']
})
export class MainScreenComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject('Window') private window: Window,
    public translate: TranslateService,
  ) {}

  isActive = false;
  activeLanguageUa = false;
  activeLanguageEn = false;

  public navIsFixed = true;
  public whiteMenu = true;
  private currentPos = 50;
  public imgSrc = 'assets/img/logo.svg';

  whatDoingScroll() {
    document.getElementById('what-doing').scrollIntoView({behavior: 'smooth'});
    // el.scrollIntoView({behavior: 'smooth'});
  }
  aboutUsBlock() {
    document.getElementById('about-us').scrollIntoView({behavior: 'smooth'});
  }
  ourClientsBlock() {
    document.getElementById('our-clients').scrollIntoView({behavior: 'smooth'});
  }
  contactBlock() {
    document.getElementById('contacts').scrollIntoView({behavior: 'smooth'});
  }

  ngOnInit() {
    this.setMenuState();

    if (  this.translate.currentLang === 'en' ) {
      this.activeLanguageEn = true;
    } else {
      this.activeLanguageUa = true;
    }
  }

  ngAfterViewInit() {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
   this.setMenuState();
  }

  @HostListener('window:mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if (event.pageY > 100) {
      this.showMenu();
    }
  }

  setMenuState() {
    const position = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;

    if (position < 850) {
      this.setDarkMode();
    } else {
      this.setWhiteMode();
    }

    if (position > this.currentPos) {
      if (this.currentPos > 100) {
        this.hideMenu();
      }
    } else {
      this.showMenu();
    }

    this.currentPos = position;
  }

  showMenu() {
    this.navIsFixed = false;
  }

  hideMenu() {
    this.navIsFixed = true;
  }

  setDarkMode() {
    this.whiteMenu = false;
    this.imgSrc = 'assets/img/logo.svg';
  }

  setWhiteMode() {
    this.whiteMenu = true;
    this.imgSrc = 'assets/img/logo-dark-word.svg';
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  setUa() {
    this.activeLanguageUa = true;
    this.activeLanguageEn = false;
  }

  setEn() {
    this.activeLanguageUa = false;
    this.activeLanguageEn = true;
  }
}
