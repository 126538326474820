import { Component, OnInit } from '@angular/core';
import {ProjectsInfoService} from '../projects-info.service';
import {NavigationEnd, Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-our-project-mobile',
  templateUrl: './our-project-mobile.component.html',
  styleUrls: ['./our-project-mobile.component.scss']
})
export class OurProjectMobileComponent implements OnInit {

  constructor(
    private projectDataInfos: ProjectsInfoService,
    private router: Router,
    public translate: TranslateService) { }

  projectListInfos: any[] = [];

  projectSlideConfig = {
    arrows: true,
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 1.2,
    infinite: false,
    prevArrow: '<div class="slick-prev prev-project" (click)="changeColorBg()"></div>',
    nextArrow: '<div class="slick-next next-project" (click)="changeColorBg()"></div>'
  }

  bgProject = ['pink', 'dark-green', 'blue'];
  currentColorIndex = 0;

  changeColorBg() {
    if (this.currentColorIndex === this.bgProject.length - 1) {
      this.currentColorIndex = 0;
    } else {
      this.currentColorIndex++;
    }
  }

  afterChange($event) {
    this.changeColorBg();
  }

  ngOnInit() {
    this.fetchData();

    this.translate.onLangChange.subscribe((e: LangChangeEvent) => {
      this.fetchData();
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  fetchData() {
    this.projectListInfos = this.projectDataInfos.getData();
  }
}
