import {Component, HostListener, OnInit} from '@angular/core';
import { ProjectsInfoService } from '../projects-info.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-our-projects',
  templateUrl: './our-projects.component.html',
  styleUrls: ['./our-projects.component.scss'],
  providers: [ProjectsInfoService]
})
export class OurProjectsComponent implements OnInit {

  constructor(
    private projectDataInfos: ProjectsInfoService,
    public translate: TranslateService) {
    this.onResize();
  }

  screenHeight: number;

  projectListInfos: any[] = [];

  firstImage = 'assets/img/projects/forum-syngenta/main.png';

  public bgProject = ['pink', 'blue', 'dark-green'];

  public currentColorIndex = 0;

  ngOnInit() {
    this.fetchData();

    this.translate.onLangChange.subscribe((e: LangChangeEvent) => {
      this.fetchData();
    });

  }

  fetchData() {
    this.projectListInfos = this.projectDataInfos.getData();
  }

  onMouseOver(item) {
    this.firstImage = item.src;
    this.changeColorBg();
  }

  changeColorBg() {
    if (this.currentColorIndex === this.bgProject.length - 1) {
      this.currentColorIndex = 0;
    } else {
      this.currentColorIndex++;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerWidth / 2;
  }
}
